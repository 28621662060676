import { v4 as uuidv4 } from 'uuid';

export default {
  install(Vue) {
    Vue.prototype.$uuid = {
      get() {
        return uuidv4();
      }
    };
  }
}; 