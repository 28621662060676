import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import UuidPlugin from "./utils/uuid";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(UuidPlugin);
Vue.filter("truncate", function (text, length = 3) {
  if (text && text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
});

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
