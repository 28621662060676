import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
    name: "login",
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    component: () => import("@/views/register/index"),
    hidden: true,
    name: "register",
    meta: {
      title: "注册",
    },
  },
  {
    path: "/payment/success",
    component: () => import("@/views/payment/success"),
    hidden: true,
    name: "paymentSuccess",
    meta: {
      title: "支付成功",
    },
  },
  {
    path: "/qrcodeWei",
    component: () => import("@/views/member/qrcodeWei"),
    hidden: true,
    name: "qrcodeWei",
    meta: {
      title: "微信支付",
    },
  },
  {
    path: "/productDoc",
    component: () => import("@/views/productDoc/index"),
    name: "productDoc",
    meta: {
      title: "产品文档",
    },
  },
  {
    path: "/T1",
    component: () => import("@/views/thinkerModel/index.vue"),
    name: "thinkerModel",
    meta: {
      title: "AI思考模型",
    },
    children: [
      {
        path: "/",
        component: () => import("@/views/thinkerModel/T1/index.vue"),
        name: "thinkerModelT1",
        meta: {
          title: "AI思考模型",
        },
      },
      {
        path: "/Q1",
        component: () => import("@/views/thinkerModel/Q1/index.vue"),
        name: "thinkerModelQ1",
        meta: {
          title: "培训老师匹配",
        },
      },
    ],
  },
  {
    path: '/enterpriseDetailInfo',
    name: 'EnterpriseDetailInfo',
    component: () => import('@/views/enterpriseDetailInfo/index.vue'),
    meta: {
      title: '企业详情'
    }
  },
  {
    path: "/",
    component: () => import("@/views/HomeView.vue"),
    name: "home",
    meta: {
      title: "首页",
      nav_top_bool: true,
    },
    children: [
      {
        path: "/",
        component: () => import("@/views/regionalAnalysis/index"),
        name: "regionalAnalysis",
        meta: {
          title: "区域分析",
        },
      },
      {
        path: "/industrialDistribution",
        component: () => import("@/views/IndustrialDistribution/index"),
        name: "industrialDistribution",
        meta: {
          title: "产业招商",
        },
        children: [
          {
            path: "/",
            component: () =>
              import("@/views/IndustrialDistribution/industry/index"),
            name: "industrialDistribution",
            meta: {
              title: "产业分布",
              icon: "el-icon-s-marketing",
            },
          },
          {
            path: "/ranking",
            component: () =>
              import("@/views/IndustrialDistribution/ranking/index"),
            name: "ranking",
            meta: {
              title: "五百强",
              icon: "el-icon-s-data",
            },
          },
        ],
      },
      {
        path: "/enterpriseInvestment",
        component: () => import("@/views/enterpriseInvestment/index"),
        name: "enterpriseInvestment",
        meta: {
          title: "企业投资",
        },
        children: [
          {
            path: "/",
            component: () =>
              import("@/views/enterpriseInvestment/aiModel/index"),
            name: "enterpriseInvestment",
            meta: {
              title: "AI模型-投资(实时优先)",
              icon: "el-icon-s-data",
            },
          },
          {
            path: "/bigData",
            component: () =>
              import("@/views/enterpriseInvestment/bigData/index"),
            name: "bigData",
            meta: {
              title: "大数据-投资(数据延迟)",
              icon: "el-icon-s-grid",
            },
          }
        ],
      },
      {
        path: "/ownData",
        component: () => import("@/views/ownData/index"),
        name: "ownData",
        meta: {
          title: "自有数据",
        },
      },
      {
        path: "/industrialChain",
        component: () => import("@/views/IndustrialChain/index"),
        name: "industrialChain",
        meta: {
          title: "产业链拆解",
        },
      },
      {
        path: "/parkFile",
        component: () => import("@/views/parkFile/index"),
        name: "parkFile",
        meta: {
          title: "园区文件",
        },
        children: [
          {
            path: "/parkFile",
            component: () => import("@/views/parkFile/ParkArea/index"),
            name: "parkFile",
            meta: {
              title: "园区文件",
              icon: "el-icon-s-order",
            },
          },
          {
            path: "/upload",
            component: () => import("@/views/parkFile/upload/index"),
            name: "upload",
            meta: {
              title: "上传文件",
              icon: "el-icon-upload",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/userInfo",
    component: () => import("@/views/userInfo/index"),
    name: "userInfo",
    meta: {
      title: "用户信息",
    },
    children: [
      {
        path: "/userInfo",
        component: () => import("@/views/userInfo/proFile/index"),
        name: "userInfoProfile",
      },
      {
        path: "/avatar",
        component: () => import("@/views/userInfo/avatar/index"),
        name: "userInfoAvatar",
      },
      {
        path: "/password",
        component: () => import("@/views/userInfo/password/index"),
        name: "userInfoPassword",
      },
      {
        path: "/phone",
        component: () => import("@/views/userInfo/phone/index"),
        name: "userInfoPhone",
      },
      {
        path: "/login-records",
        component: () => import("@/views/userInfo/loginRecord/index"),
        name: "userInfoLoginRecord",
      },
      {
        path: "/orders",
        component: () => import("@/views/userInfo/order/index"),
        name: "orders",
        meta: {
          title: "订单管理",
        },
      },
      {
        path: "/coins",
        component: () => import("@/views/userInfo/cloudCoin/index"),
        name: "cloudCoinRecord",
        meta: {
          title: "云币记录",
        },
      },
      {
        path: "/feedback",
        component: () => import("@/views/userInfo/feedback/index"),
        name: "feedback",
        meta: {
          title: "反馈中心",
        },
      },
      {
        path: "/verification",
        component: () => import("@/views/userInfo/verification/index"),
        name: "verification",
        meta: {
          title: "云币核销兑换",
        },
      },
    ],
  },
  {
    path: "/member",
    component: () => import("@/views/member/index"),
    name: "member",
    meta: {
      title: "会员中心",
    },
    children: [
      {
        path: "/member/shanhe",
        component: () => import("@/views/member/shanhe/index"),
        name: "memberShanhe",
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
